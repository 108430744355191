<template>
  <div>
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>
    <v-card v-if="event" :elevation="isEmbedded ? 0 : 2" :class="isEmbedded ? 'ma-0' : ''">
      <v-card-title v-if="!isEmbedded" class="display-2 mb-4">{{$t('profile.activities.entry.title')}}</v-card-title>
      <v-card-text v-if="!isEmbedded">
        {{$t('profile.activities.entry.tip-time-of-day')}}
      </v-card-text>
      <v-card-text :class="isEmbedded ? 'pa-0' : ''">
      <v-alert v-if="error || !valid" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
      <v-form ref="form" v-model="valid" lazy-validation v-if="activity">
        <div v-if="saved">
          <v-alert prominent type="success" icon="fadl fa fa-check-double">
            <p>Successfully saved activity.</p>
            <p>
              <v-btn :to="{name: 'event', params: {id: this.event.id }}">View results</v-btn>
              <v-btn outlined class="ml-4" @click="resetActivity">Add Another</v-btn>
            </p>
          </v-alert>
        </div>
        <v-alert v-else-if="verified" type="success" icon="fadl fa fa-check-double">
          Successfully verified code {{ code }}.
        </v-alert>
        <div v-else-if="!verified">
          <v-card-title class="px-0 subtitle py-0">{{$t('events.quickentry.verification-header')}}</v-card-title>
          <v-row class="">
            <v-col cols="12" sm="5">
              <v-text-field 
                v-model="code"
                :label="$t('events.welcome.verification-code-label')"
                persistent-hint
                :hint="$t('events.quickentry.verification-code-hint')"
                :rules="requiredFieldRules"
                />
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field 
                v-model="email"
                :label="$t('events.quickentry.email-label')"
                persistent-hint
                :hint="$t('events.quickentry.email-hint')"
                :rules="emailRules"
                />
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn class="mt-8 mb-8" color="primary" :loading="$store.getters.isLoading" @click="verify()">{{$t('shared.verify')}}</v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="verified && !saved">
          <v-card-title class="px-0 subtitle py-0">{{$t('profile.activities.entry.activity-details')}}</v-card-title>
          <v-card-text class="pa-0">      
            <div v-if="showAllActivityTypes">  
            <v-btn-toggle v-model="activity.type" @change="activityTypeChanged">
              <v-btn v-for="type in siteData.activities.filter(x => x.core)" :key="type.type" :value="type.type">
                <v-icon :title="type.text" >{{$helpers.getActivityIcon(type.type)}}</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">{{ $t('profile.activities.types.'+type.type) }}</span>
              </v-btn>
            </v-btn-toggle>
              <v-menu offset-y max-height="400">
                <template v-slot:activator="{ on, attrs }">
                  <div class="v-item-group theme--light v-btn-toggle">
                  <v-btn v-bind="attrs" v-on="on" :color="siteData.activities.some(x => x.core && x.type == activity.type)?'':'grey lighten-1'">
                    <v-icon title="More options" >fa-ellipsis-h</v-icon>
                    <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">{{$t('shared.more')}}</span>
                  </v-btn>
                  </div>
                </template>
                <v-list>
                  <v-list-item-group v-model="activity.type" @change="activityTypeChanged">
                  <v-list-item v-for="(item, idx) in siteData.activities.filter(x => !x.core)" :key="idx" :value="item.type">
                    <v-list-item-icon><v-icon v-text="$helpers.getActivityIcon(item.type)"></v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('profile.activities.types.'+item.type) }}</v-list-item-title>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            {{ activity.type ? $t('profile.activities.types.'+activity.type) : '' }}
            </div>
            <div v-else>
              <v-btn-toggle v-model="activity.type" @change="activityTypeChanged">
                <v-btn v-for="type in availableActivityTypes" :key="type" :value="type">
                  <v-icon :title="type" >{{$helpers.getActivityIcon(type)}}</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">{{ $t('profile.activities.types.'+type) }}</span>
                </v-btn>
              </v-btn-toggle>
              <v-btn text color="primary" @click="forceShowAllActivityTypes=true">{{ $t('profile.activities.show-all-type') }}</v-btn>
              {{ activity.type ? $t('profile.activities.types.'+activity.type) : '' }}
            </div>
            <br/><br/>

            <DateAndTimeWithTimeZonePicker v-model="activity.start" ref="timePicker" :timeZone="timeZoneOlson" :label="$t('profile.activities.entry.activity-date-start')" :timeLabel="$t('profile.activities.entry.activity-time-start')" />

            <v-row>
              <v-col v-if="showDistance" cols="12" sm="6">
            <DistanceTextArea
              v-if="activity.type != 'STAIR_CLIMBING'"
              v-model="activity.dist" 
              icon="fa-ruler"
              :unit="event.unit"
              :label="$t('profile.activities.entry.distance-label', { unit: unitTypeDiplay, requirement: activityService.requireDistance(activity) ? $t('shared.required') : $t('shared.optional') } )" 
              :rules="numberRules"
              >
            </DistanceTextArea>
              </v-col>
              <v-col v-if="showElevation || showElevationLoss" cols="12" sm="6">

                <DistanceTextArea
                  v-if="activity.type != 'SWIMMING' && activity.type != 'STAIR_CLIMBING' && !isDownhill"
                  v-model="computedElevationGain" 
                  icon="fa-mountain"
                  :mode="$helpers.UnitType.ELEVATION"
                  :unit="event.unit"
                  :label="$t('profile.activities.entry.elevation-label', {unit: elevationUnitTypeDiplay})" 
                  >
                </DistanceTextArea>
                <DistanceTextArea
                  v-if="isDownhill"
                  v-model="computedElevationLoss" 
                  icon="fa-mountain"
                  :mode="$helpers.UnitType.ELEVATION"
                  :unit="event.unit"
                  :label="$t('profile.activities.entry.elevation-loss-label', {unit: elevationUnitTypeDiplay})" 
                  >
                </DistanceTextArea>
              </v-col>
              <v-col v-if="activity.type == 'STAIR_CLIMBING'" cols="12" sm="6">
                <DistanceTextArea
                  v-model="activity.custom" 
                  icon="fa-sort-amount-up-alt fa-flip-horizontal"
                  :mode="$helpers.UnitType.NUMBER"
                  :unit="event.unit"
                  :label="$t('profile.activities.entry.climbed-stairs')" 
                  >
                </DistanceTextArea>
                <p class="text-muted">{{$t('profile.activities.entry.climbed-stairs-tip')}}</p>
              </v-col>
              <v-col v-else-if="showCustomField" cols="12" sm="6">
                <DistanceTextArea
                  v-model="activity.custom" 
                  icon="fa-tally"
                  :mode="$helpers.UnitType.NUMBER"
                  :unit="event.unit"
                  :label="$t('profile.activities.entry.custom')" 
                  :hint="customFieldLabel"
                  />
              </v-col>
              <v-col cols="12" sm="6" v-if="showSteps">
                <DistanceTextArea
                  v-model="activity.steps" 
                  icon="fa-shoe-prints"
                  :mode="$helpers.UnitType.NUMBER"
                  :label="$t('profile.activities.steps')" 
                  >
                </DistanceTextArea>
              </v-col>
            </v-row>

            <DurationTextArea
              v-if="showDuration"
              v-model="activity.time_s" 
              icon="fa-clock"
              :label="$t('profile.activities.entry.duration')" 
              >
            </DurationTextArea>
          </v-card-text>      

          <div v-if="geoRestrictedRaces && geoRestrictedRaces.length > 0">
            <h4>Select the area where your (entire) activity took place</h4>
            <p>You can view the exact allowed geographical areas on the <router-link :to="{name: 'event', params: {id: event.id}}">event page</router-link>.</p>
              <v-radio-group v-model="approvedRace"  column class="my-0" label="">
                <v-radio label="Not specified" :value="null" class="my-0" />
                <template v-for="(item, idx) in geoRestrictedRaces">
                  <v-radio :key="item.id" :label="item.name" :value="`${event.id}_${item.id}`" class="my-1" />
                </template>
              </v-radio-group>
              <v-checkbox v-if="false" v-model="approvedRaces" multiple :value="`${event.id}_${item.id}`" :label="item.name" hide-details/>
          </div>
          
          <v-card-text class="px-0 pt-0">   
            <v-alert v-if="infoMessage" class="mt-4" type="info">
              <vue-markdown class="markdown" :html="false" :source="infoMessage" />
            </v-alert>
            <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
            <div v-if="validationError" class="mt-8 mb-8 d-flex">
              <v-btn :disabled="!valid" color="primary" outlined :loading="$store.getters.isLoading" @click="submit(true)">{{$t('profile.activities.entry.save-anyway')}}</v-btn>
              <v-spacer/>
              <v-btn :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('profile.activities.entry.validate-again')}}</v-btn>
            </div>
            <v-btn v-else class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('shared.save')}}</v-btn>
          </v-card-text>   
        </div>
      </v-form>
      </v-card-text>
      <!-- <div id="ga_20278199"> </div><div style="text-align:right; width:300px; padding:5px 0;">
        <img src="https://bcdn.grmtas.com/images/healthyads-logo.png" alt="logo" style="float:right; border:none;" />
        <div style="width:auto; padding:1px 5px 0 0; float:right; display:inline-block; font-family:Verdana, Geneva, sans-serif; font-size:11px; color:#333;">
            <a href="https://www.healthyads.com" target="_blank" title="Health Ads" style="text-decoration:none; color:#333;">Health Ads</a> by
        </div>
      </div> -->
      <div v-if="verified" >
        <v-divider />
        <v-card-text>
          <v-card-title class="px-0 subtitle py-0">{{$t('profile.activities.track.connect-fitness-tracker')}}</v-card-title>
          <p class="mb-4">{{ $t('events.quickentry.connect-msg') }} <v-btn outlined class="ml-4" :to="{name: 'register', query: { event: event.id, verificationCode: this.code }}">{{ $t('shared.connect') }}</v-btn></p>
          <div class="clear">
            <v-sheet v-for="(img, idx) in logos" :key="idx" :elevation="0" class="text-center align-center pa-0 float-left d-inline-block mr-4 mb-4" height="40">
              <img :src="`https://sodisp.imgix.net/web/sso/${img}?h=20`" :alt="img" class="d-inline-block" style="max-width:100%; max-height:20px;"/>
            </v-sheet>
            <br class="clear"/>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import eventService from "@/services/eventService";
import activityService from "@/services/activityService";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import EventHeader from '@/components/EventHeader.vue';
import VueMarkdown from '@/components/VueMarkdown.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ManualActivityEntry",
  components: {
    VueMarkdown,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    EventHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      activityService: activityService,
      verified: false,
      saved: false,
      event: null,
      code: null,
      email: null,
      activity: null,
      availableActivityTypes: ['_ALL'],
      forceShowAllActivityTypes: false,
      approvedRace: null,
      timeZoneOlson: null,
      error: null,
      valid: true,
      siteData: siteData,
      validationError: false,
      infoMessage: null,
      requiredFieldRules: [
        v => !!v || this.$t('events.welcome.verification-code-validation-msg'),
      ],
      emailRules: [
        v => !!v || this.$t('account.register.email-is-required'),
        v => /.+@.+/.test(v) || this.$t('account.register.email-must-be-valid')
      ],
      numberRules: [
        () => activityService.validateDistance(this.activity),//() => (this.activity.dist > 0 || this.activity.type === 'OTHER' || this.activity.type === 'YOGA' || this.activity.type === 'INDOOR_CARDIO' || this.activity.type === 'INDOOR_CYCLING') || "Please enter a positive number",
      ],
      logos: ['strava-color.png', 'garmin-color.png', 'fitbit-color.jpg', 'mapmyfitness-color.png', 'polar-color.png', 'suunto-color.png', 'coros-color.png'],
    };
  },
  async mounted() {
    await this.loadEvent(this.$route.params.id);
    await this.loadData();
    this.timeZoneOlson = DateTime.now().zoneName;

    const id = this.$route.query.id;
    const profileId = this.$route.query.profileId;
    this.code = this.$route.query.code;
    this.email = this.$route.query.email;
    if (this.code && this.email) {
      await this.verify();
    }
    if (id && profileId) {
      var model = (await activityService.getQuickEntry(profileId, id)).data;
      console.log('Activity loaded', model);
      if (model) {
        this.activity = {
          id: id,
          start: model.start,
          type: model.type,
          dist: model.dist,
          elevation_gain: model.elevation_gain,
          elevation_loss: model.elevation_loss,
          kcal: model.kcal,
          steps: model.steps,
          custom: model.custom,
          time_s: model.time_s,
        }
      }
    }
    if (!this.activity) {
      this.resetActivity();
    }
    console.log('Initiated manual entry', this.activity.start);
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

  },
  methods: {
    resetActivity() {
      this.activity = {
        start: DateTime.now().startOf('day').toISO(),
        type: this.showAllActivityTypes ? 'RUNNING' : this.availableActivityTypes[0],
        time_s: 0,
      }
      this.error = null;
      this.infoMessage = null;
      this.validationError = false;
      this.saved = false;
    },
    
    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      var allTypes = [].concat.apply([], this.event.races.map(x => x.activity_types || ['_ALL']));
      this.availableActivityTypes = [... new Set(allTypes)]; // filter out duplicates
      console.log('availableActivityTypes', this.availableActivityTypes, this.event);
      EventBus.$emit('page-header-change', meta);
    },
    
    getTodayDate() {
      var date = new Date();
      date.setHours(0,0,0,0);
      return date;
    },

    async loadData() {
      if (this.user) {
      }
    },

    activityTypeChanged() {
      console.log('this.activity.type',this.activity.type);
      if (this.activity.type == 'STAIR_CLIMBING') {
        this.activity.dist = 1;
      }
      if (this.$refs.form.value === false) {
        // validation depends on activity type, so revalidate pls
        // only do when currently invalid to prevent that switching activity type shows 
        this.$refs.form.validate(); 
      }
    },

    async verify() {
      const model = {
            code: this.code,
            email: this.email,
      };
      this.error = null;
      this.validationError = false;
      var data = (await eventService.verifyQuickEntry(this.event.id, model)).data;
      console.log('verify result', data);
      if (data.status == "OK"){
        this.verified = true;
      }
      else if (data.status == "INFO") {
        this.infoMessage = data.msg;
        this.validationError = true;
      }
      else {
        this.error = data.msg;
        this.validationError = false;
      }
    },

    async submit(ignoreValidation) {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;
        try {
          //var validation = (await activityService.validate(this.activity)).data;
          console.log('Storing manual entry', this.activity);
          var model = {
            code: this.code,
            email: this.email,
            provider: 'quick_entry',
            approved_race_ids: this.approvedRace ? [this.approvedRace] : null,
            ...this.activity,
          };
          var data = (await eventService.quickEntry(this.event.id, model, ignoreValidation)).data;
          if (data.status == "OK"){
            this.saved = true;
            this.$helpers.toastResponse(this, data, 'Activity saved successfully.');
            //if (!this.isEmbedded) {
            //  this.$router.push({ name: 'event', params: { id: this.event.id } });
            //}
          }
          else if (data.status == "INFO") {
            this.infoMessage = data.msg;
            this.validationError = true;
          }
          else {
            this.error = data.msg;
            this.validationError = false;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('profile.activities.manualentry'), disabled: true },
      ];
    },
    geoRestrictedRaces() {
      if (!this.event) return [];
      return this.event.races.filter(x => x.geofence);
    },
    showAllActivityTypes() {
      return this.forceShowAllActivityTypes || !this.availableActivityTypes || this.availableActivityTypes.length == 0 || this.availableActivityTypes.some(x => x === '_ALL') || this.availableActivityTypes.length > 5;
    },
    unitTypeDiplay() {
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    elevationUnitTypeDiplay() {
      return this.event == null || this.event.unit == 'METRIC' ? 'm' : 'ft';
    },
    isDownhill() {
      return this.activity && (this.activity.type == 'ALPINE_SKIING' || this.activity.type == 'SNOWBOARDING');
    },
    showDistance() {
      return this.activity && !activityService.zeroDistanceActivities(this.activity);
    },
    showSteps() {
      return this.activity && ['DAILY_SUMMARY'].some(x => x === this.activity.type);
    },
    showCustomField() {
      return this.activity && this.event && this.event.races.some(x => x.scoring == 'CUSTOM') && (['VOLUNTEERING', 'OTHER'].some(x => x === this.activity.type))
    },
    customFieldLabel() {
      return this.showCustomField && this.event.races.filter(x => x.scoring == 'CUSTOM')[0].custom;
    },
    showDuration() {
      return !this.showSteps;
    },
    showElevation() {
      return this.activity && !['SWIMMING'].some(x => x === this.activity.type) && this.showDistance;
    },
    showElevationLoss() {
      return this.activity && (this.isDownhill || this.activity.type == 'CYCLING');
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    computedElevationGain:{
      get () {
        return this.activity ? this.activity.elevation_gain : null
      },
      set (value) {
        this.activity.elevation_gain = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    computedElevationLoss:{
      get () {
        return this.activity ? this.activity.elevation_loss : null
      },
      set (value) {
        this.activity.elevation_loss = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },

  },

};
</script>
<style lang="scss">
.v-input .v-messages {min-height: 0;;}
</style>

